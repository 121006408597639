declare var require: any

export const environment = {
	appVersion: require('../../../../package.json').version,
	environment_name: "production",
	production: true,
	api_url: "https://accelerateapi.consumeroptix.com/api/",
	quote_pdf_output: "http://accelerateapi.consumeroptix.com/reports/*id*-lifequote-proposal.pdf",
	quote_video_url: "https://renderqueuev2.consumeroptix.com//api/queue/returnvideo/*id*",
	stripe_key: "pk_live_51HTr9YDxkMSbNauGLdmUwQHwQupTtjIf1nuPEkMji4I4fudh6dyEsr29mewkzm7mhef4JL7LlxqauHjGOb8kn94600O0DcDCU1",
	share_video_url: "https://accelerateapi.consumeroptix.com/Video.aspx",
	bugsnag_api_key: "b765f15a02ebef8abbfe9f8bd401d47b",
	base_url: "https://accelerate.consumeroptix.com/",
	mixpanel_api_token: "d3e996a341394e59072f0713bac3a378",
	mixpanel_api_proxy: "https://mixpanel.consumeroptix.com",
	share_dtc_url: "https://accelerateapi.consumeroptix.com/Dtc.aspx",
};
